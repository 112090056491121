import { Fragment, useEffect, useState } from "react"
import { useWebSocket } from "./App"

const ReceiveBroadcastComponent = () => {

    const [broadcastMessage, setBroadcastMessage] = useState("")

    const socket = useWebSocket()
    useEffect( () => {
        if(socket) {
            socket.on("ping", (data) => {
                setBroadcastMessage(data.msg)
            })
            return () => { socket.off("ping") /* Unsubsribe from event PING falls die Komponente gelöscht wird */}
        } 
    }, [socket])

    return(<Fragment>
        <h1>Broadcast message: {broadcastMessage}</h1>
    </Fragment>)
}

export default ReceiveBroadcastComponent